import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Divider } from "@mui/material";
import { parentBoxStyles } from "../../styles/box";
import { TopHeader } from "../../components/top-header";

import { useNavigate, useSearchParams } from "react-router-dom";
import { AnimatedCircles } from "../../components/animationPayment/internal";
import { useQuery } from "@apollo/client";
import { GET_PAYMENT_BY_TRANSACTION_ID } from "../../gql/payment";

export const PaymentSuccess: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const failed = searchParams.get('status') === 'failed';
  const transactionId = searchParams.get('transactionId');
  const [paymentExist, setPaymentExist] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const { data: paymentData } = useQuery(GET_PAYMENT_BY_TRANSACTION_ID, {
    variables: {
      transactionId: transactionId,
    },
  });

  const payments = paymentData?.getPaymentByTransactionId;

  const data = {
    TransactionNumber: transactionId,
    TotalAmount: 100 * payments?.length,
    TransactionDate: payments ? payments[0]?.createdDate : ''
  };

  useEffect(() => {
    if (payments && payments.length > 0) {
      setPaymentExist(true);
    }
  }, [payments]);

  return (
    <Box pt={7}>
      {!loading && (
        <TopHeader title="Payment Confirmation" link="/my-account" />
      )}
      <Box sx={parentBoxStyles} style={{ backgroundImage: "none" }}>
        <Box style={{ width: "100%", textAlign: "center" }}>
          <Box>
            {loading ? (
              <AnimatedCircles /> // Display animation while loading
            ) : (
              <>
              {failed ? (
                <Box>
                  <img
                    src="./payment-failed.png"
                    alt="Failed Payment"
                    style={{ width: "200px", marginBottom: "20px" }}
                  />
                  <Typography
                    variant="h1"
                    fontSize={40}
                    fontFamily={"ABC Gravity"}
                    fontWeight={700}
                    color="#4f0336"
                    sx={{ pb: 2 }}
                  >
                    Payment Failed
                  </Typography>
                  <Typography
                    variant="body2"
                    fontSize="16px"
                    color="#777777"
                    mb={2}
                  >
                    We apologize for the inconvenience, but it seems that there was an issue processing your payment. Please double-check your payment details and try again. If the problem persists, feel free to contact our support team for assistance. Thank you for your understanding.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate("/contact-us")}
                  >
                    <Typography textTransform={'none'}>Contact Support</Typography>
                  </Button>
                </Box>) : (
                  <>
                  {paymentExist ? (
                <Box>
                  <img
                    src="/confirmed.svg"
                    alt="Successful Payment"
                    style={{ width: "200px", marginBottom: "20px" }}
                  />
                  <Typography
                    variant="h1"
                    fontSize={40}
                    fontFamily={"ABC Gravity"}
                    fontWeight={700}
                    color="#4f0336"
                    sx={{ pb: 2 }}
                  >
                    Payment Successful
                  </Typography>
                  <Typography
                    variant="body2"
                    fontSize="16px"
                    color="#777777"
                    mb={2}
                  >
                    Details of the transaction are included below
                  </Typography>
                  <Divider sx={{ marginBottom: "20px" }} />
                  {Object.entries(data).map(([key, value]) => (
                    <Box
                      key={key}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <Typography variant="body2" fontSize="16px" color="black">
                        {key.replace(/([A-Z])/g, " $1").trim()}:
                      </Typography>
                      <Typography variant="body2" fontSize="16px" color="black">
                        {value}
                      </Typography>
                    </Box>
                  ))}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate("/my-account")}
                    sx={{ marginTop: 2 }}
                  >
                  <Typography textTransform={'none'}> Back to My Account</Typography>
                   
                  </Button>
                </Box>) : (
                  <Box>
                  <img
                    src="/not-confirmed.svg"
                    alt="Successful Payment"
                    style={{ width: "200px", marginBottom: "20px" }}
                  />
                  <Typography
                    variant="h1"
                    fontSize={40}
                    fontFamily={"ABC Gravity"}
                    fontWeight={700}
                    color="#4f0336"
                    sx={{ pb: 2 }}
                  >
                    Payment does not exist
                  </Typography>
                  <Typography
                    variant="body2"
                    fontSize="16px"
                    color="#777777"
                    mb={2}
                  >
                    We didn't find the details associated with transaction id {transactionId}.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate("/")}
                    sx={{ marginTop: 2 }}
                  >
                  <Typography textTransform={'none'}> Back to Home</Typography>
                   
                  </Button>
                </Box>
                )}
                </>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
